<template>
  <div class="action-container" :class="{ 'active-actions': isOpen }">
    <div class="inline-block mx-1 relative pt-1">
      <div class="action-settings-icon shadow-md">
        <i
          class="fa-light fa-chevrons-right cursor-pointer"
          :class="{ rotate: isOpen }"
          @click="handleActions"
        ></i>
      </div>
      <div class="">
        <ul>
          <li
            v-for="(action, index) in actions"
            :key="index"
            :draggable="true"
            @dragstart="onDragStart($event, 'input', action)"
            class="flex items-center p-1 cursor-pointer column-gap-3 h-8 text-sm max-h-7"
          >
            <!-- <ActionItem v-if="activElement === action.id" /> -->
            <div>
              <i
                :class="action?.flowchart?.icon?.class"
                class="action-icon"
              ></i>
              <span v-if="isOpen" class="ml-2 inline-block">
                {{ action.flowchart?.title }}</span
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject } from "vue";
import useIvrs from "@/modules/xpbx/composables/useIvrs";

export default {
  name: "FlowActions",

  setup(props, { emit }) {
    const t = inject("t");
    const isOpen = ref(false);
    const activElement = ref(null);
    const { getActions, actions } = useIvrs();

    const handleActions = () => {
      isOpen.value = !isOpen.value;
    };

    const onDragStart = (event, nodeType, action) => {
      activElement.value = action.id;
      emit("drag-action", action);
      event.dataTransfer.setData("application/node-type", nodeType);
    };

    onMounted(async () => {
      await getActions();
    });

    return {
      isOpen,
      actions,
      //   Methods
      onDragStart,
      activElement,
      handleActions,
      //   Drag functions
      onDragStart,
    };
  },
};
</script>

<style lang="scss" scoped>
.action-settings-icon {
  font-size: 1rem;
  color: #10b981;
  right: -22px;
  position: absolute;
  z-index: 11;
  top: 50%;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate {
  transform: rotate(180deg);
}

.active-actions {
  min-width: 190px;
}

.action-container {
  border-right: 1px solid rgb(229, 231, 235);
  /* padding: 0 10px; */
}
</style>