<template>
  <!-- You can use the `BaseEdge` component to create your own custom edge more easily -->
  <BaseEdge :id="id" :style="style" :path="path[0]" :marker-end="markerEnd" />

  <!-- Use the `EdgeLabelRenderer` to escape the SVG world of edges and render your own custom label in a `<div>` ctx -->
  <EdgeLabelRenderer>
    <div
      :style="{
        pointerEvents: 'all',
        position: 'absolute',
        transform: `translate(-50%, -50%) translate(${path[1]}px,${path[2]}px)`,
      }"
      class="nodrag nopan"
    >
      <i
        class="fa-solid fa-trash delete-icon"
        v-if="style?.stroke === '#f05a94'"
        @click="removeEdges(id)"
      ></i>
    </div>
  </EdgeLabelRenderer>
</template>

<script setup>
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  useVueFlow,
} from "@vue-flow/core";
import { computed } from "vue";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  sourceX: {
    type: Number,
    required: true,
  },
  sourceY: {
    type: Number,
    required: true,
  },
  targetX: {
    type: Number,
    required: true,
  },
  targetY: {
    type: Number,
    required: true,
  },
  sourcePosition: {
    type: String,
    required: true,
  },
  targetPosition: {
    type: String,
    required: true,
  },
  markerEnd: {
    type: String,
    required: false,
  },
  style: {
    type: Object,
    required: false,
  },
  edge: {
    type: [Object, Array, String, undefined, null],
    required: false,
  },
});

const { removeEdges } = useVueFlow();

const path = computed(() => getBezierPath(props));
</script>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style scoped>
.delete-icon {
  color: #f05a94;
  font-size: 1.5rem;
  cursor: pointer;
}
</style>

