<template>
  <div>
    <div class="field flex justify-start items-start flex-col mt-2 relative">
      <!-- Info icon -->
      <div class="flex items-center justify-between w-full">
        <label
          :for="data?.options?.name"
          v-if="
            data?.options?.label &&
            checkOptionsType(data?.options?.type, data?.options?.multiple)
          "
        >
          {{ getPlaceholderValue(data?.options?.label) }}
        </label>
        <SettingFormInfo :data="data.options.help" v-if="data?.options?.help" />
      </div>
      <!-- Input type -->
      <InputText
        :id="data?.options?.name"
        :value="modelValue"
        v-if="data?.options?.type === 'text'"
        :placeholder="getPlaceholderValue(data?.options.placeholder)"
        @input="inputChange($event.target.value)"
      />

      <!-- Select type -->
      <div
        v-if="
          data?.options?.type === 'select' &&
          data?.options?.value?.type === 'static' &&
          !data?.options?.multiple &&
          !data?.options?.name
        "
      >
        <Dropdown
          v-model="selectedValue"
          @change="onValueChange"
          :options="
            transformOptions(data?.options?.value?.values, data?.options)
          "
          optionLabel="name"
          placeholder="Select a option"
          class="w-full md:w-14rem"
        />
        <!-- New search select -->
        <FlowSelectSearchKeyValue
          :id="data?.options?.name"
          :name="data?.options?.name"
          :hint="data?.options?.hint"
          :options="
            transformOptions(data?.options?.value?.values, data?.options)
          "
          @setValue="onValueChange"
          v-model="selectedValue"
          :optionLabel="findOptionLabel(data?.options)"
          :headerLabel="getPlaceholderValue(data?.options?.label)"
        />
      </div>

      <div
        v-if="
          data?.options?.type === 'select' &&
          data?.options?.value?.type === 'static' &&
          data?.options?.multiple
        "
      >
        <MultiSelect
          v-model="selectedMultiValue"
          @change="onValueMultyChange"
          :options="
            transformOptions(data?.options?.value?.values, data?.options)
          "
          optionLabel="name"
          :placeholder="selectText"
          :maxSelectedLabels="3"
          class="w-full md:w-80"
        />
      </div>
      <!-- Ajax Select type -->
      <div
        v-if="
          data?.options?.type === 'select' &&
          data?.options?.value?.type === 'dynamic'
        "
      >
        <FlowSelectSearchKeyValue
          :id="data?.options?.name"
          :name="data?.options?.name"
          :hint="data?.options?.hint"
          v-if="axajOptions.length"
          :options="axajOptions"
          v-model="selectedValue"
          @setValue="onValueChange"
          :optionLabel="findOptionLabel(data?.options)"
          :headerLabel="getPlaceholderValue(data?.options?.label)"
        />
      </div>

      <!-- Timerange -->
      <OpeningHours
        :openingHours="openingHours"
        v-if="data?.options?.type === 'timerange' && isOpeningHours"
        @changeRange="changeRange"
      />

      <!-- Switch type -->
      <InputSwitch
        v-model="isActive"
        v-if="data?.options?.type === 'switch'"
        @change="changeIsActive"
      />

      <span
        class="text-red-900 capitalize inline-block mt-2 text-sm"
        v-if="errors"
      >
        {{ errors[data?.options?.name] }}
      </span>
      <!-- Hint -->
      <QueueHint
        v-if="data?.options?.hint"
        :title="$t(`xpbx.settings.flows.hints.${data.options.hint}`)"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { inject, ref, computed, onMounted, nextTick } from "vue";
import { useVueFlow } from "@vue-flow/core";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import useIvrs from "@/modules/xpbx/composables/useIvrs";
import FlowSelectSearchKeyValue from "@/shared/FlowSelectSearchKeyValue.vue";
import OpeningHours from "@/modules/xpbx/pages/settings/timezones/components/OpeningHours";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";
import SettingFormInfo from "@/modules/xpbx/pages/settings/flow-version-detail/components/SettingFormInfo";

export default {
  name: "SettingForm",

  emits: ["update:modelValue"],

  components: {
    InputText,
    Dropdown,
    MultiSelect,
    OpeningHours,
    InputSwitch,
    QueueHint,
    SettingFormInfo,
    FlowSelectSearchKeyValue,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    modelValue: {
      required: true,
    },

    errors: {
      required: false,
    },
  },

  setup(props, { emit }) {
    const t = inject("t");
    const store = useStore();
    const { updateNode, nodes } = useVueFlow();
    const selectText = t("xpbx.settings.dropdown.select_option");
    const selectedValue = ref(props.modelValue || selectText);
    const selectedMultiValue = ref();
    const isActive = ref(false);
    const isOpeningHours = ref(false);
    const openingHours = ref({
      monday: [["00:00:00", "24:00:00"]],
      tuesday: [["00:00:00", "24:00:00"]],
      wednesday: [["00:00:00", "24:00:00"]],
      thursday: [["00:00:00", "24:00:00"]],
      friday: [["00:00:00", "24:00:00"]],
      saturday: [["00:00:00", "24:00:00"]],
      sunday: [["00:00:00", "24:00:00"]],
    });

    const { getIVRDynamicOptions, dynamicOptions } = useIvrs();

    const axajOptions = computed(() => {
      if (dynamicOptions.value.length > 0) return dynamicOptions.value;

      return [];
    });

    const selectedNodeId = computed(() => store.state.xadmin.selectedNodeId);

    const getPlaceholderValue = (value) => {
      if (!value) return null;
      return t(`xpbx.settings.ivrs.actions.${value}`);
    };

    const checkOptionsType = (type, isMUltiple = false) => {
      if (isMUltiple) return true;

      return type === "text" || type !== "select" || type === "static";
    };

    const transformOptions = (options, optionsValues) => {
      let valueKey = "name";

      if (optionsValues?.multiple) valueKey = "code";

      const optionsData = [];
      options.forEach((element) => {
        const option = {};

        for (const item in element) {
          option.name = element[item];
          option[valueKey] = element[item];
        }

        optionsData.push(option);
      });

      return optionsData;
    };

    const onValueChange = () => {
      emit(
        "update:modelValue",
        selectedValue.value?.value || selectedValue.value
      );
    };

    const onValueMultyChange = () => {
      const values = selectedMultiValue.value.map((value) => value.code);

      emit("update:modelValue", values);
    };

    const findOptionLabel = (options) => {
      const mapArray = options.value?.map;
      return mapArray[mapArray.length - 1];
    };

    const changeRange = ({ day, ranges }) => {
      openingHours.value[day] = ranges;

      emit("update:modelValue", openingHours.value);
    };

    const changeIsActive = (e) => {
      emit("update:modelValue", isActive.value);
    };

    const inputChange = (value) => {
      if (props.index === 0) {
        const title = document.querySelector(`#${selectedNodeId.value}`);
        if (title) title.innerHTML = value;

        updateNode(selectedNodeId.value, { label: value });
        store.commit("xadmin/UPDATE_XADMIN_NODE_TITLE", value);
      }

      emit("update:modelValue", value);
    };

    const fillFormWithValues = (value) => {
      if (!value) return;
      const options = props.data?.options;
      const type = props.data?.options?.type;

      if (type === "switch") {
        isActive.value = value;
      } else if (type === "timerange") {
        openingHours.value = value;
      } else if (type === "select") {
        const selectType = options?.value?.type;
        const isMultiple = options?.multiple;

        // Check for multiple select
        if (isMultiple) {
          const multipleValues = value.map((item) => {
            return {
              name: item,
              code: item,
            };
          });
          selectedMultiValue.value = multipleValues;
        } else {
          if (selectType === "dynamic") {
            // Dynamic select
            const selVal = dynamicOptions.value.find(
              (item) => item.id === value
            );
            if (selVal) {
              selectedValue.value = { ...selVal };
            }
          } else {
            // Static select
            selectedValue.value = value;
          }
        }
      } else {
        selectedValue.value = value;
      }
    };

    onMounted(async () => {
      if (props.data?.options?.value?.type === "dynamic") {
        const options = props.data.options;
        await getIVRDynamicOptions(options.value?.url, options);
      }

      // Check for existed values
      fillFormWithValues(props.modelValue);

      await nextTick();
      isOpeningHours.value = true;
    });

    return {
      nodes,
      axajOptions,
      dynamicOptions,
      selectedValue,
      selectedMultiValue,
      openingHours,
      isActive,
      selectText,
      selectedNodeId,
      isOpeningHours,
      // Methods
      changeRange,
      updateNode,
      inputChange,
      getPlaceholderValue,
      transformOptions,
      checkOptionsType,
      findOptionLabel,
      onValueChange,
      changeIsActive,
      onValueMultyChange,
      getIVRDynamicOptions,
    };
  },
};
</script>

