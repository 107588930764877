<template>
  <div v-if="showForm">
    <div class="flow-setting-form scrollable-wrapper p-6">
      <div v-for="(item, index) in selectedSettings?.fields" :key="index">
        <SettingForm
          :data="item"
          :index="index"
          :errors="errors"
          v-model="form[item?.options?.name]"
        />
      </div>
    </div>
    <Divider />
    <div class="mt-1 flex items-center justify-end p-2">
      <Button
        class="ml-5"
        text
        @click="createSettings"
        :label="$t('xpbx.button.save')"
      />
      <Button text @click="closeDialog" :label="$t('xpbx.button.cancel')" />
      <ConfirmPopup></ConfirmPopup>
      <Button
        text
        class="delete-button"
        @click="deleteNode(item)"
        :label="$t('xpbx.button.delete')"
      />
    </div>
  </div>
</template>

<script>
import { computed, nextTick, onMounted, ref, inject } from "vue";
import { useStore } from "vuex";
import Button from "primevue/button";
import ConfirmPopup from "primevue/confirmpopup";
import Divider from "primevue/divider";
import { useConfirm } from "primevue/useconfirm";
import { mapFormData } from "@/services/transformService";
import useIvrValidation from "@/modules/xpbx/composables/useIvrValidation";
import SettingForm from "@/modules/xpbx/pages/settings/flow-version-detail/components/SettingForm.vue";

export default {
  name: "FlowSettings",

  components: {
    Button,
    SettingForm,
    ConfirmPopup,
    Divider,
  },

  setup(props, { emit }) {
    const t = inject("t");
    const store = useStore();
    const confirm = useConfirm();
    const form = ref({});
    const showForm = ref(false);
    const { validate, errors, resetErrors } = useIvrValidation();

    const selectedSettings = computed(
      () => store.state.xadmin.selectedSettings
    );

    const selectedOptions = computed(
      () => store.state.xadmin.selectedMenuOptions
    );

    const deleteNode = (item) => {
      confirm.require({
        target: event.currentTarget,
        message: t("xpbx.settings.ivr_versions.dialogs.confirm_delete_flow"),
        rejectProps: {
          label: t("xpbx.button.cancel"),
          severity: "secondary",
          outlined: true,
        },
        acceptProps: {
          label: t("xpbx.button.delete"),
          severity: "danger",
        },
        accept: () => {
          emit("delete", item);
        },
        reject: () => {
          closeDialog();
        },
      });
    };

    const createSettings = () => {
      const hasErrors = validate(form.value, selectedSettings.value);

      if (hasErrors) return;

      const formData = mapFormData(form.value);
      // return;

      store.commit("xadmin/SET_XADMIN_NODE_MENU_OPTIONS", form.value);
      store.commit("xadmin/UPDATE_XADMIN_SETTINGS_VERSION", formData);

      emit("close");
    };

    const closeDialog = () => {
      resetErrors();
      emit("close");
    };

    onMounted(async () => {
      resetErrors();
      if (selectedOptions.value) form.value = { ...selectedOptions.value };
      await nextTick();
      showForm.value = true;
    });

    return {
      form,
      showForm,
      errors,
      confirm,
      selectedOptions,
      //   Methods
      validate,
      deleteNode,
      resetErrors,
      closeDialog,
      selectedSettings,
      createSettings,
    };
  },
};
</script>

<style lang="scss" scoped>
.delete-button {
  span {
    color: #f05a94;
  }
}

.p-fluid .p-button {
  width: initial;
}

.custom-dialog-heading .p-divider.p-divider-horizontal {
  margin-top: 12px;
}

.flow-setting-form {
  min-height: 300px;
  max-height: 65vh;
  overflow: hidden;
  overflow-y: auto;
}
</style>