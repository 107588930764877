import { inject, ref, watch } from "vue";

/**
 * @returns {string} - A unique id.
 */

/**
 * In a real world scenario you'd want to avoid creating refs in a global scope like this as they might not be cleaned up properly.
 * @type {{draggedType: Ref<string|null>, isDragOver: Ref<boolean>, isDragging: Ref<boolean>}}
 */
const state = {
  /**
   * The type of the node being dragged.
   */
  errors: ref(null),
  hasErrors: ref(null),
};

export default function useIvrValidation() {
  const t = inject("t");
  const { errors, hasErrors } = state;

  const validateFormElement = ({ data, rule, key }) => {
    const value = data?.toLowerCase();

    checkRequiredRegex(key, value, rule);

    // switch (rule) {
    //   case "/a-z/":
    //     checkRequiredRegex(key, value, /[a-z]+$/);
    //     break;
    //   case "/^(a-z)/":
    //     checkRequiredRegex(key, value, /^[a-z]+$/);
    //     break;
    //   case "/[a-z0-9_.#@&+-]*/i":
    //     checkRequiredRegex(key, value, /[a-z0-9_.#@&+-]*/);
    //     break;
    //   case "/1-9/":
    //     checkRequiredRegex(key, value, /[1-9]/);
    //     break;
    //   case "/1-9/*#":
    //     checkRequiredRegex(key, data, /^[1-9]*\*#$/);
    //     break;
    // }

    return hasErrors.value;
  };

  const checkRequiredRegex = (key, value, regex) => {
    if (!value || value === null || value === "") {
      errors.value = {
        ...errors.value,
        [key]: `${key} ${t("xpbx.settings.ivrs.actions.required")}.`,
      };
      hasErrors.value = true;

      return false;
    } else {
      const pattern = new RegExp(regex);
      console.log("pattern", pattern);
      const isValid = pattern.test(value);
      console.log("isValid", isValid, "Value", value, "Key", key);
      // const isValid = regex.test(value);

      if (!isValid) {
        errors.value = {
          ...errors.value,
          [key]: `${key} ${t("xpbx.settings.ivrs.actions.invalid")}.`,
        };
        hasErrors.value = true;
      } else {
        errors.value = { ...errors.value, [key]: null };
        hasErrors.value = false;
      }
    }
  };

  const validate = (form, options) => {
    const formValues = options.fields.map((i) => i.options.name);

    for (const key of formValues) {
      const option = options.fields.find((i) => i.options.name === key);

      const isRequired = option?.options?.required;
      const rule = option?.options?.value?.validate;

      if (rule || isRequired) {
        const isValid = validateFormElement({ data: form[key], rule, key });
      }
    }

    return hasErrors.value;
  };

  const resetErrors = () => {
    errors.value = null;
    hasErrors.value = false;
  };

  return {
    errors,
    // Methods
    validate,
    resetErrors,
  };
}
