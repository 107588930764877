<template>
  <div class="custom-node relative">
    <div class="node-header" :id="data.id" @click="openActionSettings">
      {{ data.label }}
    </div>
    <div class="node-ports">
      <div class="port-input port-item">
        <div
          v-for="(item, index) in data.data.inputs"
          :key="index"
          @click="openInputSettings('input', index, item)"
          class="port-input-item"
        >
          <!-- <div class="triangle-inner triangle"></div> -->
          <Handle
            :id="`input-${index}`"
            type="target"
            :position="Position.Left"
            class="triangle triangle-inner"
          />
          {{ item.label }}
        </div>
      </div>
      <div class="port-output port-item">
        <div
          v-for="(item, index) in data.data.outputs"
          :key="index"
          @click="openInputSettings('output', index, item)"
          class="port-output-item relative"
        >
          {{ item.label }}

          <Handle
            :id="`output-${index}`"
            type="source"
            :position="Position.Right"
            class="triangle triangle-out"
          />
        </div>
      </div>
    </div>
    <div
      class="icon-input-container icon-container"
      @click="openSettings('input')"
      v-if="data?.data?.dynamicInputs"
    >
      <i class="fa-light fa-plus"></i>
    </div>
    <div
      class="icon-container"
      @click="openSettings('output')"
      v-if="data?.data?.dynamicOutputs"
    >
      <i class="fa-light fa-plus"></i>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { Handle, useHandleConnections, Position } from "@vue-flow/core";

export default {
  name: "CustomNode",
  props: {
    data: Object,
  },

  components: {
    Handle,
  },

  setup(props, { emit }) {
    const store = useStore();
    const actions = computed(() => store.state.xadmin.flowActions);
    const sourceConnections = useHandleConnections({
      type: "target",
    });

    const targetConnections = useHandleConnections({
      type: "source",
    });

    const openSettings = (value) => {
      emit("open-settings", {
        data: { data: props.data, value },
        value: null,
      });
    };

    const openInputSettings = (key, index, item) => {
      if (!item?.type && item?.type !== "dynamic") return;

      emit("open-settings", {
        data: { data: props.data, value: null },
        value: {
          key,
          index,
          item,
        },
      });
    };

    const openActionSettings = () => {
      let dialogOptions = props.data?.data?.dialogOptions;

      const menuOptions = props.data?.data?.menuOptions || null;

      if (!dialogOptions || !dialogOptions?.fields?.length) {
        const actionId = props.data?.action_id || props.data?.data?.action_id;
        if (actionId) {
          const existedAction = actions.value.find(
            (action) => action.id === actionId
          );

          if (existedAction) {
            dialogOptions = existedAction.dialog;
            store.commit("xadmin/SET_XADMIN_COMMAND", existedAction.action);
          }
        }
      }

      if (!dialogOptions?.fields?.length) return;

      const data = {
        data: dialogOptions,
        nodeId: props.data.id,
      };

      if (dialogOptions) emit("openDialog", { data, menuOptions });
    };

    return {
      actions,
      Position,
      sourceConnections,
      targetConnections,
      openSettings,
      openInputSettings,
      openActionSettings,
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-node {
  background-color: #fff;
  border: 1px solid #10b981;
  border-radius: 5px;
  width: 250px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
}

.node-header {
  background-color: #10b981;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  text-align: left;
}

.node-ports {
  margin-top: 10px;
  display: flex;
  width: 100%;
}

.port-input {
  text-align: left;
}

.port-input-item {
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding-left: 16px;
}

.port-output {
  text-align: right;
}

.port-output-item {
  margin: 4px 4px 4px 0;
}

.port-item {
  width: 50%;
}

.icon-container {
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: #10b981;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  right: 10px;
  bottom: 10px;
}

.icon-input-container {
  right: initial;
  left: 10px;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 15px solid gray;
  opacity: 0.7;
  transform: rotate(90deg);
  background: transparent;
  border-radius: 0;
}

.triangle-out {
  position: absolute;
  right: -20px;
  top: 4px;
}

.triangle-inner {
  top: initial;
}
</style>
