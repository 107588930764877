export const transformRawNodes = (nodes) => {
  if (!nodes?.length) return [];

  return nodes.map((node) => {
    return {
      id: node.id.toString(),
      action_id: node?.action_id || null,
      // type: "custom",
      label: node?.label,
      inputs: node?.data?.inputs || [],
      outputs: node?.data?.outputs || [],
      menu_options: node?.data?.menu_options?.length
        ? node.data.menu_options
        : node?.data?.menuOptions || [],
      dynamicInputs: node?.data?.dynamicInputs || false,
      dynamicOutputs: node?.data?.dynamicOutputs || false,
      flowchart: {
        left: node.position.x,
        top: node.position.y,
      },
    };
  });
};

export const transformRawEdges = (edges) => {
  if (!edges?.length) return [];

  return edges.map((edge, index) => {
    return {
      id: `${index}`,
      type: "special",
      dst: {
        connector_id: `${edge.source}`,
        menu_id: `${edge.target}`,
        sourceHandle: `${edge.sourceHandle}`,
        targetHandle: `${edge.targetHandle}`,
      },
    };
  });
};

const transformValue = (key, value) => {
  switch (key) {
    // case "is_active":
    //   return value === 0 ? false : true;
    case "play_audio_msg_id":
    case "command_options":
    case "on_error_play_audio_msg_id":
    case "on_timeout_play_audio_msg_id":
      return value?.id || value;

    default:
      return value;
  }
};

const transformFormValues = (key, value, values) => {
  switch (key) {
    // case "is_active":
    //   return value === 0 ? false : true;
    case "play_audio_msg_id":
    case "on_error_play_audio_msg_id":
    case "on_timeout_play_audio_msg_id":
      return value.id;

    default:
      return value;
  }
};

export const mapFormData = (formData) => {
  const form = {};

  for (const key in formData) {
    form[key] = transformValue(key, formData[key]);
  }

  return form;
};

export const mapFormValues = (formValues) => {
  const form = {};

  for (const key in formValues) {
    form[key] = formValues[key];
  }

  return form;
};
