<template>
  <div class="flow-container">
    <VueFlow
      :nodes="nodes"
      :edges="edges"
      :fit-view="true"
      class="vue-flow"
      ref="vueFlowRef"
      @connect="onConnect"
      @drop="onDrop"
      @dragover="onDragOver"
      @edgeClick="handleEdgeClick"
      @node-drag-stop="onNodeMove('drag stop', $event)"
    >
      <template #node-custom="props">
        <CustomNode
          :data="props"
          @open-settings="openSettings"
          @openDialog="openDialogSetting"
        />
      </template>

      <template #edge-custom="buttonEdgeProps">
        {{ buttonEdgeProps }}
        <EdgeWithButton
          :id="buttonEdgeProps.id"
          :source-x="buttonEdgeProps.sourceX"
          :source-y="buttonEdgeProps.sourceY"
          :target-x="buttonEdgeProps.targetX"
          :target-y="buttonEdgeProps.targetY"
          :source-position="buttonEdgeProps.sourcePosition"
          :target-position="buttonEdgeProps.targetPosition"
          :marker-end="buttonEdgeProps.markerEnd"
          :style="buttonEdgeProps.style"
          :edge="buttonEdgeProps"
        />
      </template>
    </VueFlow>

    <!-- Input/ Output DIalog-->
    <Dialog
      v-model:visible="createDialog"
      :style="{ width: '500px' }"
      :header="$t('xpbx.settings.ivr_versions.dialogs.create_flow_input')"
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <div class="field mb-1">
        <label for="name">{{
          $t("xpbx.settings.ivr_versions.labels.name")
        }}</label>
        <InputText
          id="name"
          v-model.trim="record.name"
          required="true"
          autofocus
        />
      </div>
      <QueueHint :title="$t('xpbx.settings.ivr_versions.hints.name')" />

      <div class="field mb-1">
        <label for="name">{{
          $t("xpbx.settings.ivr_versions.labels.description")
        }}</label>
        <InputText
          id="description"
          v-model="record.description"
          required="true"
          autofocus
        />
      </div>
      <span v-if="validateDescription" class="text-sm text-red-900">{{
        validateDescription
      }}</span>
      <QueueHint :title="$t('xpbx.settings.ivr_versions.hints.description')" />

      <Divider />
      <template #footer>
        <div class="flex items-center justify-end">
          <Button text @click="create" :label="$t('xpbx.button.save')" />
          <Button
            text
            @click="closeCreateDialog"
            :label="$t('xpbx.button.cancel')"
          />
          <ConfirmPopup></ConfirmPopup>
          <Button text @click="deleteInput" :label="$t('xpbx.button.delete')" />
        </div>
      </template>
    </Dialog>
    <!-- Node settings Dialog -->
    <Dialog
      v-model:visible="settingsDialog"
      :style="{ minWidth: '800px' }"
      :header="settingDialogTitle"
      :modal="true"
      :dismissableMask="true"
      :resizable="true"
      class="p-fluid relative custom-dialog-heading custom-flow-dialog"
    >
      <FlowSettings @close="settingsDialog = false" @delete="deleteNode" />
    </Dialog>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted, computed, watch, inject } from "vue";
import ConfirmPopup from "primevue/confirmpopup";
import { useRoute } from "vue-router";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { useConfirm } from "primevue/useconfirm";
import useIvrs from "@/modules/xpbx/composables/useIvrs";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";
import { VueFlow, useVueFlow } from "@vue-flow/core";
import CustomNode from "@/modules/xpbx/pages/settings/flows/components/CustomNode.vue";
import EdgeWithButton from "@/modules/xpbx/pages/settings/flows/components/EdgeWithButton.vue";
import Divider from "primevue/divider";
import FlowSettings from "@/modules/xpbx/pages/settings/flow-version-detail/components/FlowSettings.vue";

export default {
  name: "VueFlowContainer",

  components: {
    VueFlow,
    CustomNode,
    Dialog,
    InputText,
    Button,
    Divider,
    QueueHint,
    ConfirmPopup,
    FlowSettings,
    EdgeWithButton,
  },
  setup() {
    const t = inject("t");
    const route = useRoute();
    const store = useStore();
    const zoom = ref(1);
    const confirm = useConfirm();
    const vueFlowRef = ref(null);
    const isEditing = ref(null);
    const selectedNode = ref(null);
    const selectedRecord = ref(null);
    const validateDescription = ref(null);
    const createDialog = ref(false);
    const settingsDialog = ref(false);
    const record = ref({ name: "" });

    const {
      addEdges,
      getViewport,
      onNodeDrag,
      screenToFlowCoordinate,
      nodes: vueNodes,
      edges: vueEdges,
    } = useVueFlow();

    const { getIvrVersionData, updateIvrVersionSettings } = useIvrs();

    const nodes = computed(() => store.state.xadmin.nodes);
    const edges = computed(() => store.state.xadmin.edges);
    const version = computed(() => store.state.xadmin.version);
    const zoomPosition = computed(() => store.state.xadmin.zoomData);
    const selectedNodeId = computed(() => store.state.xadmin.selectedNodeId);
    const draggableNode = computed(() => store.state.xadmin.draggableNode);
    const fitPosition = computed(() => store.state.xadmin.fitPosition);
    const settingDialogTitle = computed(() => {
      if (store.state.xadmin.nodeTitle) return store.state.xadmin.nodeTitle;

      return "Add IVRS settings";
    });

    const fetchFlow = async () => {
      const ivrId = route.params.id;
      const versionId = route.params.versionId;
      await getIvrVersionData(ivrId, versionId);
    };

    // const deleteEdge = () => {
    //   vueEdges.value = vueEdges.value.filter(
    //     (edge) => edge.id !== selectedRecord.value
    //   );
    // };

    const handleEdgeClick = (event) => {
      const { edge } = event;

      const edgeId = event?.edge?.id;

      if (!edgeId) return;

      edge.style = { stroke: "#f05a94" };
      edge.label = "";
      edge.data = { icon: "fa-check" };

      selectedRecord.value = edgeId;
    };

    const openSettings = (form) => {
      const { data, value } = form;

      if (value?.item) {
        const form = {
          name: value?.item?.label,
          description: value?.item?.value,
        };

        record.value = form;

        isEditing.value = {
          key: value?.key,
          index: value?.index,
        };
      } else {
        record.value = { name: "", description: "" };
        isEditing.value = null;
      }
      selectedNode.value = data;
      createDialog.value = true;
    };

    const resetForm = () => {
      record.value = { name: "", description: "" };
      isEditing.value = null;
      validateDescription.value = null;
    };

    const validateInputForm = () => {
      const regex = /^[0-9A-Fa-f*#]+$/;
      const description = record.value.description;

      if (!description) return false;

      if (!regex.test(description)) return false;

      return true;
    };

    const create = async () => {
      const isValid = validateInputForm();

      if (!isValid) {
        if (!record.value.description) {
          validateDescription.value = t(
            "xpbx.settings.ivr_versions.errors.description"
          );
        } else {
          validateDescription.value = t(
            "xpbx.settings.ivr_versions.errors.description_error"
          );
        }

        return;
      }

      // xpbx.settings.ivr_versions.errors.description_error
      if (isEditing.value) {
        // Update Node
        store.commit("xadmin/UPDATE_XADMIN_NODE_INPUT", {
          value: record.value,
          key: isEditing.value.key,
          index: isEditing.value.index,
          nodeId: selectedNode.value?.data?.id,
        });
      } else {
        const node = {
          id: selectedNode.value?.data?.id,
          key: selectedNode.value?.value,
          value: {
            label: record.value.name,
            value: record.value.description,
          },
        };
        store.commit("xadmin/UPDATE_XADMIN_NODE", node);
      }

      resetForm();
      createDialog.value = false;
    };

    const onNodeMove = (type, event) => {
      const { node } = event;
      const position = node.position;
      const nodeId = node.id;
      store.commit("xadmin/UPDATE_XADMIN_NODE_POSITION", {
        position,
        nodeId,
      });
    };

    const deleteInputHandle = () => {
      store.commit("xadmin/DELETE_XADMIN_NODE_INPUT", {
        value: record.value,
        key: isEditing.value.key,
        index: isEditing.value.index,
        nodeId: selectedNode.value?.data?.id,
      });

      createDialog.value = false;
    };

    const deleteInput = () => {
      confirm.require({
        target: event.currentTarget,
        message: t("xpbx.settings.ivr_versions.dialogs.confirm_delete"),
        rejectProps: {
          label: t("xpbx.button.cancel"),
          severity: "secondary",
          outlined: true,
        },
        acceptProps: {
          label: t("xpbx.button.delete"),
          severity: "danger",
        },
        accept: () => {
          deleteInputHandle();
        },
        reject: () => {
          //  Close Dialog
          createDialog.value = false;
        },
      });
    };

    const checkInputOutput = (edge) => {
      const sourceHandle = edge.sourceHandle.split("-");
      const targetHandle = edge.targetHandle.split("-");

      const source = sourceHandle[0];
      const target = targetHandle[0];

      if (source !== target) return true;

      return false;
    };

    const onConnect = (edge) => {
      const isValidConnection = checkInputOutput(edge);

      const customEdge = {
        ...edge,
        type: "custom",
        // label: `${edge.source} to ${edge.target}`,
        style: { stroke: "#10b981" },
        labelStyle: { fill: "#10b981", fontWeight: 700 },
        labelBgStyle: { fill: "white" },
        labelBgPadding: [6, 3],
        labelBgBorderRadius: 3,
      };
      // store.commit("xadmin/CONNECT_XADMIN_EDGES", edge);
      if (isValidConnection) addEdges([customEdge]);
    };

    // Drag and Drop eevnts
    const onDragOver = (event) => {
      event.preventDefault();
    };

    const onDrop = (event) => {
      event.preventDefault();
      const position = getDropPosition(event);
      const newNode = {
        ...draggableNode.value,
        position,
      };

      store.commit("xadmin/UPDATE_XADMIN_NODES", newNode);
    };

    const getDropPosition = (event) => {
      const position = screenToFlowCoordinate({
        x: event.clientX,
        y: event.clientY,
      });

      return position;
    };

    const openDialogSetting = (data) => {
      store.commit("xadmin/SET_XADMIN_SETTINGS", data);
      settingsDialog.value = true;
    };

    const deleteNode = async () => {
      store.commit("xadmin/DELETE_XADMIN_NODE", selectedNodeId.value);
      settingsDialog.value = false;
      // const newVersion = { ...version.value, is_active: 0 };

      // newVersion.schema.menus = newVersion.schema.menus.filter(
      //   (node) => node.id !== selectedNodeId.value
      // );

      // await updateIvrVersionSettings(
      //   route.params.id,
      //   route.params.versionId,
      //   newVersion
      // );
    };

    const closeCreateDialog = () => {
      createDialog.value = false;
      resetForm();
    };

    watch(
      () => zoomPosition.value,
      (value) => {
        if (!value) return;

        // Set Zoom
        vueFlowRef.value.setTransform({
          x: value.x,
          y: value.y,
          zoom: value.zoom,
        });
      },
      { deep: true }
    );

    watch(
      () => fitPosition.value,
      (value) => {
        if (!value) return;

        // Fit View Port
        vueFlowRef.value.fitView();
      },
      { deep: true }
    );

    onMounted(async () => {
      await fetchFlow();
    });

    return {
      nodes,
      edges,
      version,
      zoom,
      record,
      confirm,
      isEditing,
      onNodeMove,
      zoomPosition,
      selectedNodeId,
      createDialog,
      settingsDialog,
      vueFlowRef,
      selectedNode,
      draggableNode,
      fitPosition,
      selectedRecord,
      settingDialogTitle,
      validateDescription,
      // Methods
      create,
      resetForm,
      openSettings,
      onConnect,
      deleteInput,
      getViewport,
      deleteNode,
      handleEdgeClick,
      deleteInputHandle,
      // Drag and drop events
      onDrop,
      onDragOver,
      getDropPosition,
      openDialogSetting,
      closeCreateDialog,
    };
  },
};
</script>

<style>
.flow-container {
  height: 100vh;
  width: 100%;
  flex: 1;
  min-width: 80vw;
}

.vue-flow {
  height: 100%;
  width: 100%;
}

.custom-flow-dialog .p-dialog-content{
  padding: 0;
}
</style>
