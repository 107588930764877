<template>
  <div class="flex w-full relative">
    <FlowActions @action="action" @drag-action="dragAction" />
    <div class="relative flex-1">
      <div class="save-buttons-wrapper">
        <BaseBreadcrumb :data="breadcrumbData" v-if="ivr?.name" />
        <div>
          <Button
            :label="$t('xpbx.button.reset')"
            class="mr-2 add-record-button"
            @click="reset"
          />
          <Button :label="$t('xpbx.button.save')" class="mr-2" @click="save" />
        </div>
      </div>
      <VueFlowContainer />
    </div>
    <div class="zoom-button-container" @click="zoomCenter">
      <i class="fa-light fa-window-restore"></i>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useVueFlow } from "@vue-flow/core";
import VueFlowContainer from "./components/VueFlowContainer.vue";
import BaseBreadcrumb from "./components/Breadcrumb.vue";
import FlowActions from "./components/FlowActions.vue";
import useIvrs from "@/modules/xpbx/composables/useIvrs";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import {
  transformRawNodes,
  transformRawEdges,
} from "@/services/transformService";
import Button from "primevue/button";

export default {
  name: "FlowVersionDetail",

  components: {
    PageHeader,
    Button,
    VueFlowContainer,
    FlowActions,
    BaseBreadcrumb,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const {
      ivr,
      findIvr,
      getIvrVersionData,
      deleteIvrVersion,
      updateIvrVersionSettings,
    } = useIvrs();
    const { nodes, edges, dimensions, getTransform, getViewport } =
      useVueFlow();
    const nodesData = computed(() => store.state.xadmin.nodes);
    const ivrVersion = computed(() => store.state.xadmin.version);

    const breadcrumbData = computed(() => {
      return [
        { label: "IVRS", route: "/xpbx/settings/flows" },
        {
          label: `${ivr?.value?.name || "IVR Version Detail"}`,
          route: `/xpbx/settings/flow/${route?.params?.id}?tab=version`,
        },
        {
          label: ivrVersion?.value?.name || "New IVR Version",
        },
      ];
    });

    const generateNode = (data, id) => {
      return {
        id: `random_node-${id}`,
        action_id: data?.id,
        type: "custom",
        label: data?.flowchart.title,
        position: {
          x: Math.random() * dimensions.value.width,
          y: Math.random() * dimensions.value.height,
        },
        data: {
          label: `Node ${id}`,
          dialogOptions: data?.dialog,
          inputs: data?.flowchart.inputs,
          outputs: data?.flowchart.outputs,
          menu_options: {
            command: data?.action,
          },
          dynamicInputs: data?.flowchart.dynamic_inputs,
          dynamicOutputs: data?.flowchart.dynamic_outputs,
        },
        sourcePosition: "right",
        targetPosition: "left",
      };
    };

    const action = (action) => {
      const id = nodes.value.length + 1;
      const newNode = generateNode(action, id);

      // store.commit("xadmin/UPDATE_XADMIN_NODES", newNode);
    };

    const generateRandomString = (length = 6) => {
      const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += letters.charAt(Math.floor(Math.random() * letters.length));
      }
      return result;
    };

    const dragAction = (action) => {
      const renadomId = generateRandomString();
      const id = nodes.value.length + renadomId;
      const newNode = generateNode(action, id);

      store.commit("xadmin/SET_XADMIN_DRAGGABLE_NODE", newNode);
    };

    const save = async () => {
      const { zoom, x, y } = getTransform();
      const roundedX = x.toFixed(0);
      const roundedY = y.toFixed(0);
      const roundedZoom = zoom.toFixed(2);

      const transformMatrix = `matrix(${roundedZoom}, 0, 0, ${roundedZoom}, ${roundedX}, ${roundedY})`;

      // Transform the nodes and edges
      const nodesData = transformRawNodes(nodes.value);
      const edgesData = transformRawEdges(edges.value);

      const id = route.params.id;

      const versionId = route.params.versionId;
      const data = {
        name: ivrVersion?.value?.name,
        // is_active: ivrVersion?.value?.is_active || false,
        is_active: ivrVersion?.value?.is_active === true ? 1 : 0,
        panzoom: transformMatrix,
        schema: {
          menus: nodesData,
          links: edgesData,
          panzoom: transformMatrix,
        },
      };

      await updateIvrVersionSettings(id, versionId, data);
    };

    const reset = async () => {
      const ivrId = route.params.id;
      const versionId = route.params.versionId;
      await getIvrVersionData(ivrId, versionId);
    };

    const calculateCenter = (nodes) => {
      if (!nodes || nodes.length === 0) return { centerX: 0, centerY: 0 };

      let minX = Number.MAX_VALUE;
      let minY = Number.MAX_VALUE;
      let maxX = Number.MIN_VALUE;
      let maxY = Number.MIN_VALUE;

      nodes.forEach((node) => {
        const { x, y } = node.position;
        if (x < minX) minX = x;
        if (y < minY) minY = y;
        if (x > maxX) maxX = x;
        if (y > maxY) maxY = y;
      });

      return { minX, maxX, minY, maxY };
    };

    const zoomCenter = () => {
      const { minX, minY, maxX, maxY } = calculateCenter(nodes.value);

      const width = maxX - minX;
      const height = maxY - minY;
      let centerX = width / 2;
      centerX = centerX < 300 ? 300 : centerX;
      centerX = centerX > 1200 ? 1200 : centerX;
      let centerY = height / 2;
      centerY = centerY < 200 ? 200 : centerY;
      centerY = centerY > 600 ? 600 : centerY;

      let zoom = Math.min(
        window.innerWidth / width,
        window.innerHeight / height
      );

      if (zoom > 0.6) zoom = 0.6;
      const calculatedZoom = zoom * 0.8;

      const viewport = {
        x: centerX,
        y: centerY,
        zoom: calculatedZoom,
      };

      store.commit("xadmin/SET_XADMIN_FLOW_FIT_VIEW", viewport);
    };

    onMounted(async () => {
      await findIvr(route.params.id);
    });

    return {
      ivr,
      nodesData,
      ivrVersion,
      breadcrumbData,
      //  Methods
      save,
      reset,
      action,
      dragAction,
      zoomCenter,
      generateNode,
      calculateCenter,
      deleteIvrVersion,
      generateRandomString,
      updateIvrVersionSettings,
    };
  },
};
</script>

<style lang="scss" scoped>
.save-buttons-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 20px; */

  .p-button {
    padding: 4px 16px;
  }
}

.add-record-button {
  background-color: #f05a94;
  border: 1px solid #f05a94;
  opacity: 0.9;

  &:hover {
    background-color: #f05a94;
    border: 1px solid #f05a94;
    opacity: 1;
  }
}

.zoom-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 12;
  cursor: pointer;
}
</style>
