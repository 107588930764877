<template>
  <div
    v-tooltip.top="data"
    class="information-icon w-4 h-4 flex items-center justify-center text-white rounded-full ml-auto mb-1"
  >
    <i class="fa-light fa-info text-xs"></i>
  </div>
</template>

<script>
import Tooltip from "primevue/tooltip";

export default {
  name: "SettingFormInfo",

  directives: {
    tooltip: Tooltip,
  },

  props: {
    data: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.information-icon {
  background-color: #32bcad;
  cursor: pointer;
}
</style>